#bootstrap-overrides h1 {
  font-size: 40px; 
  color: #DAFFED; 
  font-weight: 900;
}

#bootstrap-overrides .buttonPrimary {
  color: #1F2B37;
  font-weight: 900;
  background-color: #FFC709;
  border-color: #FFC709;
}

#bootstrap-overrides .buttonRound {
  border-radius: 100px;
  font-size: 65px;
  height: 75px;
  width: 75px;
  line-height: 50px;
}

#bootstrap-overrides .header-menu a {
  color: #9BF3F0;
  text-decoration: none;
}

.portfolioItem .caption {
  display: none;
}

.portfolioItem:hover .caption {
  display: block;
}

.portfolioItem:hover img {
  opacity: 0.3;
}

@media (max-width: 767px) {
  .hideMobile {
    display: none;
  }
}